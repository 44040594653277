import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Container from '../components/Container'
import Layout from '../components/Layout'

export default () => {
	const data = useStaticQuery(query)

	return (
		<Layout>
			<Container width={ 560 }>
				<Img
					fluid={ data.promotion.childImageSharp.fluid }
					alt="Promotional offer"
				/>
			</Container>
		</Layout>
	)
}

const query = graphql`
	query {
		promotion: file(relativePath: { eq: "promotion/promotion.png" }) {
			childImageSharp {
				fluid(maxWidth: 800) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
	}
`
